import request from '@/utils/request.js'
// 获取一分一档列表，分页查询 /dev/api
export const REQlevelscore = (query) => {
  return request({
    method: 'get',
    // url: '/system/levelscore/queryByLevelYearSub',
    url: '/system/levelscore/queryByLevelYearSubAll',
    params: query
  })
}
// 获取院校排行  /dev/api/system/college/queryListByYear
export const REQcollegeRang = (query) => {
  return request({
    method: 'get',
    url: '/system/college/queryListByYear',
    params: query
  })
}
//  新增批次线
export const REGbatchLineAdd = (query) => {
  return request({
    method: 'post',
    url: ' ',
    data: query
  })
}
// 批次线分页  /dev/api
export const REGlistPage = (query) => {
  return request({
    method: 'get',
    url: '/system/line/listPage',
    params: query
  })
}
// 学科评估 查询一级
export const evaluationCategories = (query) => {
  return request({
    method: 'get',
    url: '/system/evaluation/getcategorylists',
    params: query
  })
}
//  学科评估 查询二级
export const queryBySubject = (query) => {
  return request({
    method: 'get',
    url: '/system/evaluation/getmajorcategorylist',
    params: query
  })
}
// //  学科评估 查询三级
export const queryBySubjectThree = (query) => {
  return request({
    method: 'get',
    url: '/system/evaluation/getsubjectnamelist',
    params: query
  })
}
//  根据专业大类和专业名称 获取学科整体分和学院代码名称
export const queryByMajorAndSubject = (query) => {
  return request({
    method: 'get',
    url: '/system/evaluation/getDataBySubjectName',
    params: query
  })
}
// 查询提前批
export const queryTqp = (query) => {
  return request({
    method: 'get',
    url: '/system/scoreplan/queryPlanZx',
    params: query
  })
}
// 获取首页轮播图
export function bannerList(params) {
  return request({
    url: '/system/navbar/list',
    method: 'get',
       params,
  })
}
// 查询测一测 霍兰德测试题
export function queryTest1() {
  return request({
    url: '/system/takeatest/queryTest1',
    method: 'get'
  })
}
// 获取测一测 霍兰德的结果
export const queryTest1Anwser = (query) => {
  return request({
    method: 'get',
    url: '/system/takeatest/queryTest1Anwser',
    params: query
  })
}
// 查询测一测 DISC测试题
export function queryTest3() {
  return request({
    url: '/system/takeatest/queryTest3',
    method: 'get'
  })
}
// 获取测一测 DISC的结果
export const queryTest3Anwser = (query) => {
  return request({
    method: 'get',
    url: '/system/takeatest/queryTest3Anwser',
    params: query
  })
}
// 查询测一测 MBTI测试题
export const queryTest2 = (query) => {
  return request({
    method: 'get',
    url: '/system/takeatest/queryTest2',
    params: query
  })
}

// 获取测一测 MBTI结果
export const queryTest2Anwser = (query) => {
  return request({
    method: 'get',
    url: '/system/takeatest/queryTest2Anwser',
    params: query
  })
}
// 获取测一测轮播图
export const testbannerList = (query) => {
  return request({
    method: 'get',
    url: '/system/takeatest/list',
    params: query
  })
}
// 查询测一测列表
export function takeaTestlist(params) {
  return request({
    url: '/system/takeatest/takeaTestlist',
    method: 'get',
    params,
  })
}
export function getzzvip(params) {
  return request({
    url: '/system/userDetails/getzzvip',
    method: 'post',
    data: params
  })
}
// post 测试请求
export function remssdfsdfsdf(params) {
  return request({
    url: '/system/consults/rems',
    method: 'post',
    data: params,
    isNeedString:true
  })
}

<template>
  <div class="">
    <!-- 测一测 -->
    <div class="left_information" style="height: 40px;margin: 20px 0 12px">
      <div class="ceyicetitle"><span style="position: relative;z-index: 9;">测一测</span> </div>
      <div class="ceyicenum">参与测试人数：{{ ceyiceToastNum}}</div>
      <!-- <div class="more" style="line-height: 50px;">更多 ></div> -->
    </div>
    <div class="ceyiceItem" :style="{marginRight:index == 2 ? '0px' : '78px'}" v-for="(item, index) in ceyicelist" :key="index" @click="tz(item)">
      <img :src="item.takeaTestUrl" alt="">
      <span class="indexFzTwo">{{ item.testTitle }}</span>
    </div>
  </div>
</template>

<script>
import { takeaTestlist } from "@/api/homeIndex.js"

import {
  gkzyCommonParameters
} from "@/api/commonparams.js"
export default {
  name: 'ceYice',
  data() {
    return {
      ceyicelist: [
        {
          takeaTestUrl: require("@/assets/imgs/ceyice.png"),
          testTitle: '霍兰德职业兴趣测试',
          type: '1'
        },
        {
          takeaTestUrl: require("@/assets/imgs/ceyice.png"),
          testTitle: 'MBTI性格测试 ',
          type: '2'
        },
        {
          takeaTestUrl: require("@/assets/imgs/ceyice.png"),
          testTitle: 'DISC性格测试',
          type: '3'
        }
      ],
      ceyiceToastNum: ''
    }
  },
  methods: {
    tz(item) {
      if (!localStorage.getItem('phonenumber')) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)


        return
      }
      if (this.$store.state.userInfo.userType == '01') {
        this.$router.push({ path: 'vip' });
        this.$message.warning('请先购买会员')
        return
      }
      this.$router.push({ path: 'takeatestItem', query: { type: item.type } });
    },

  },
  mounted() {
    // 获取测一测人数
    gkzyCommonParameters({
      parameterType: '测一测人数'
    }).then(res => {
      this.ceyiceToastNum = res.rows[0].parameterValue
    })
    // 获取测一测列表
    takeaTestlist().then(res=>{
      this.ceyicelist = res.rows.map(item=>{
        return {
          ...item,
          takeaTestUrl:process.env.VUE_APP_BASE_API  +item.takeaTestUrl
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.left_information {
  display: flex;
  height: 40px;
}
.ceyicetitle {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 15px;
  position: relative;
}

.ceyicetitle::before {
  content: "";
  width: 60px;
  height: 8px;
  background: #74d19d;
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 2;
}

.ceyicenum {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #bdbdbd;
  align-self: flex-end;
  line-height: 40px;
}
.ceyiceItem {
  display: inline-block;
  width: 27%;
  margin-right: 78px;
  img{
    width: 100%;
    margin-bottom: 8px;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    font-size: 16px;
    font-family: PingFang SC;
    // margin-top: 12px;
    font-weight: 500;
    color: #000000;
  }
}
</style>

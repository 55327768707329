<template>
    <div>
            <!-- 轮播图 -->
    <el-carousel  height="360px" style="width: 100%;" :autoplay="false" arrow="always">
      <el-carousel-item v-for="(item, index) in introductionList" :key="index">
        <div class="bannerItemDiv" style="height: 100%;" :style="{'background':`url(${item.testBarUrl}` }"></div>

        <!-- <img height="100%" width="100%" :src="item.testBarUrl" alt=""> -->
      </el-carousel-item>
    </el-carousel>

        <div class="ceshi minw">
            <ceYice></ceYice>
        </div>
    </div>
</template>

<script>
import ceYice from '@/components/ceyice.vue'
import {testbannerList} from "@/api/homeIndex.js"
export default {
    name: 'listIndex',
    components: {
        ceYice
    },
    data() {
      return {
        introductionList:[]
      }
    },
    mounted(){
      testbannerList().then(res => {
        this.introductionList = res.rows.map(item => {
          return { ...item, testBarUrl: process.env.VUE_APP_BASE_API + item.testBarUrl }
        })
      })
    }
}
</script>

<style lang="scss" scoped>
.ceshi {
    // width: 55%;
    // margin: auto;
    // margin-top: 10%;
    // margin-left: 11%;
    margin-top: 32px;
}
</style>
